/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a1ab1338b04b4a15-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bb6334b8f5a99b5e-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/994367e55fdd7fef-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/539b2ecbf014924b-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/dad619d2ef97f73d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e0e418e0c2fc8a84-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7c7db451c1a82f61-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/01b6d16db7cbd96b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d9e386ae70efc2f0-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d0f96be320385a33-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c898cbfd2f789a8c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b9472d49e3bc18c3-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b5c08a795ae281ca-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2744aa005c8cf586-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/69eca568ddda66f3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/470a067cc6557a8c-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a4e8963e7141b0f0-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d536eaaa8eee0a4d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c954d99df1178b91-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8fa52275b6c22437-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/46b92c15a48f3318-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e4f65e35dde2bee1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6194a65a1b989dc8-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/418bb9d724f84584-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/025c5221126e4e89-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6a5d8dc148518b96-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e7814bd1d06a39b6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7f5a4bbe7ec7be95-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ac0efabfe978b0ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7d1684f14ddac155-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/82233a533941ac93-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0881a2b922b3331e-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/994bf73bb06543dc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/279b47070a5d5877-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/045832894acda0e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/27971e35634b7c88-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cd31bf4b34f8dfb3-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ecf49d904668b268-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2f66f084fba01545-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/906678b269849541-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/674abd25bb7be96f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/da897b99eb1fe4a1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/80b1a0e600ca6d83-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7c16c8204ab29534-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f756da832d8c34d4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/98a28a5430a3cf7f-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df2942b6de9d14b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7a7012758df5a81e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_53ca5a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6ebb97b5c9fa4e03-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_53ca5a';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_53ca5a {font-family: '__Roboto_53ca5a', '__Roboto_Fallback_53ca5a'
}.__variable_53ca5a {--font-roboto: '__Roboto_53ca5a', '__Roboto_Fallback_53ca5a'
}

